import React from 'react';
import {navigate} from 'gatsby';
import {compose} from 'recompose';
import {ReactTypeformEmbed} from 'react-typeform-embed';
import * as ROUTES from '../constants/routes';

import {withAuthorization, withEmailVerification} from '../components/Session';

// eslint-disable-next-line react/prefer-stateless-function
class MerchantPayment extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
            <a href="/wallet">
                <picture>
                <img src="/pay.png" width="95%"/>
                </picture>
            </a>    
          );
	}
}

export default (props) => <MerchantPayment {...props} />;
